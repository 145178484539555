@import "../../styles/variables.scss";

.availability-page {
	position: relative;
	width: 100%;
	// height: 5000px;
	background: #f4f4f4;
	.main_container {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		// flex-wrap: wrap;
		// -webkit-flex-flow: row wrap;
		align-items: flex-end;
		padding: 1rem;
		width: 100vw;
		height: calc(#{$vh} * 100);
		background: #f4f4f4;

		@include responsive {
			padding-top: calc(var(--header-height) + 1rem);
			flex-direction: column;
			padding-bottom: 0;
		}

		@include mobileSafari {
			height: calc(var(--initial-vh, 1vh) * 100);
		}

		.building-col {
			order: 1;
			width: calc((100vw - 2rem) * 0.2);
			flex-shrink: 0;
			line-height: 1;
			.building-svg {
				position: relative;
				width: 100%;
				
				@include responsive {
					float: none;
					margin: 0 auto;
					padding: 0;
					width: 100%;
					height: 100%;
				}
			}

			@include responsive {
				width: 100% !important;
				position: relative;
				text-align: center;
				height: calc(((#{$vh} * 100) - var(--header-height) - 1rem) * 0.4);
				padding: 0;
				flex-grow: 1;
			}

			@include mobileSafari {
				height: calc(((var(--initial-vh, 1vh) * 100) - var(--header-height) - 1rem) * 0.4);
			}
		}

		.table-col {
			order: 2;
			width: calc(65vw - 1.2rem);
			margin-left: auto;

			@include responsive {
				width: 100%;
				flex-grow: initial;
			}

			.unit-table-container {
				position: relative;
				overflow-y: scroll;
				max-height: calc(((((100vw - 2rem) * 0.2) - 3rem) * 1.99) + 1rem) !important;

				@include responsive {
					max-height: calc(((#{$vh} * 100) - var(--header-height) - 1rem) * 0.6) !important;
				}
			}

			.unit-table {
				width: 100%;
				font-family: neuzeit-grotesk, sans-serif;
				font-weight: 300;
				font-style: normal;
				border-collapse: separate;
				border-spacing: 0;

				// @media (min-width: 2000px) {
				// 	table-layout: fixed;
				// }

				tbody tr:hover {
					td {
						background-color: #f1f1f1;
					}
				}

				tbody tr td {
					padding: 1rem 0;
					border-bottom: 1px solid $table-border;
					font-size: 2.6rem;
					line-height: 2rem;
					white-space: nowrap;
					cursor: pointer;
					width: 0.1%;
					transition: background-color 500ms;

					@include responsive {
						font-size: 1.5em;
						white-space: normal;
						font-weight: 400;
					}

					@include phones {
						font-size: 1.2rem;
					}

					&:last-child {
						text-align: right;

						a {
							color: inherit;
						}
					}
				}

				// tbody tr:last-child {
				// 	td {
				// 		border-bottom: 0;
				// 	}
				// }

				thead tr th {
					font-weight: normal;
					letter-spacing: 0;
					border-bottom: 1px solid $table-border;
					background: transparent;
					padding: 1rem 0;
					white-space: nowrap;
					position: sticky;
					top: 0;
					background-color: #f4f4f4;
					user-select: none;
					text-align: left;

					.column-label {
						margin-right: 0.25rem;
					}

					.anticon {
						opacity: 0;
					}

					&:last-child {
						text-align: right;
					}

					&.sortable {
						cursor: pointer;
					}

					&.sorting {
						.anticon {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

.building-svg {
	// width: 20rem;
	// float: right;
	// padding-right: 3rem;
	// bottom: -9px;
	&.building-front {
		#FRONT {
			visibility: visible;
		}

		#BACK {
			visibility: hidden;
		}
	}

	&.building-back {
		#FRONT {
			visibility: hidden;
		}

		#BACK {
			visibility: visible;
		}
	}
}
.logo-plan-svg {
	width: 3rem;
	margin-left: auto;

	@include responsive {
		width: 2rem;
	}
}

.deselected-box {
	opacity: 0 !important;
}

.selected-box {
	fill-opacity: .5 !important;
	fill: $red !important;
}