@import "../../styles/variables.scss";
.home-page {
	position: relative;
	width: 100%;
	height: 5000px;
	.hidemobile {
		@include responsive {
			display: none !important;
		}
	}
	.hidedesktop {
		display: none;
		color: $grey !important;
	}
	.building-container {
		position: relative;
		background: $white;
		z-index: 303;
		width: 100%;
		// height: 5000px;
		font-size: 0;

		img {
			width: 100%;

			@include responsive {
				height: calc(#{$vh} * 100);
				object-fit: cover;
			}
		}

		.overlay {
			@include phones {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.4);
				z-index: 1;
			}
		}
	}
	.mainContent {
		top: var(--header-height);
		position: fixed;
		width: 100%;
		z-index: 20;

		// @include touch {
		// 	position: relative;
		// }

		.home_section_two{
			#image_with_description{
				// padding-top:0;
			}
		}
		.home_section_three{
			// align-items: flex-end;
			// background-color: red;
			flex-wrap: wrap;
			-webkit-flex-flow: row wrap;
			flex-flow: row wrap;
		
			#image_only{
				order: 1;
				// align-items: flex-start;
				width: 100%;
				padding:  0 ;
				height: auto;
				.image_container{
					order:1;
					width:100%;
					align-self: flex-start;   
					.image_animated{
						height: calc((#{$vh} * 100) - var(--header-height));
						max-width: 100%;
						background-size: cover !important;  
						background-position: top;

						@include phones {
							height: calc(((#{$vh} * 100) - var(--header-height)) * 0.4);
						}

						@include tablet {
							height: calc(((#{$vh} * 100) - var(--header-height)) * 0.5);
						}
					}
				}
			}
			#header_text{
				order: 2;
				padding: 1rem;
				align-self: flex-end !important;
				.description{
					display:none
				}
			}
			// @include phones {
			// 	#header_text{
			// 		padding:  1rem;
			// 		align-self: flex-end !important;
			// 		align-items: flex-end;

			// 	}
			// 	#image_only{
			// 		height: auto!important
			// 	}
			// }
			// @include responsive{
			// 	#image_only{
			// 		display: flex;
			// 		flex-direction: column;
			// 		height: calc(100vh - 6.5rem);

			// 		#header_text {
			// 			margin-top: auto;
			// 		}
			// 	}
			// }
		}
		.home_section_four{
			#image_with_description{
				padding-top:0;
			}
		}
		
	}

	.sections {
		> * {
			background-color: white;
			position: relative;
			z-index: 1;
			transform: translate3d(0, 0, 0);
		}
	}
}
// .buildingImageHeight,
// .video-holder {
// 	@include touch {
// 		width:100%;
// 		height: calc(#{$vh} * 100);
// 		object-fit: cover;
// 	}
// }
.header-text {
	position: fixed;
	right: 0;
	top: 0;
	text-align: right;
	color: white;
	padding-top: 26.9%;
	width: 60%;
	z-index: 303;
	display: flex;
	pointer-events: none;

	@include tablet {
		width: 75%;
		padding-top: 45%;
	}

	@include responsive{
		width: 100%;
		padding-top: 40%;
	}

	.Plx {
		width: 100%;
		transform: translateY(-100%);
		padding: 4vw;
		padding-right: 1rem;

		@include phones {
			transform: none;
			padding: 0.9rem;
		}
	}

	h3 {
		margin-bottom: 0 !important;
		color: white;
	}
}

.building {
	min-height: 100vh;
	height: 168vw;
	width: 100%;
	object-fit: cover;
}