$darkGrey: #707070;
$white: #f4f4f4;
$red: #f95c4f;
$grey: #606060;
$map-park:#95be9a;
$darkGrey: #787878;
$table-border: #b8bbbc;
$table-header-text: #555e60;
$table-row-text: #9a9a9a;

$body-font-weight: 400;

$xs_mobile_min: 320px;
$xs_mobile_max: 767px;

$md_tablet_min: 767px;
$md_tablet_max: 1199px;

$lg_desktop_small_min: 1200px;
$lg_desktop_small_max: 1366px;

$xl_desktop_medium_min: 1367px;
$xl_desktop_medium_max: 1600px;

$xxl_desktop_large: 1601px;
$xxl_desktop_large_max:1920px;

$footer_height: 5.52rem;

$vh: var(--vh, 1vh);

@mixin phones {
	@media (min-width: #{$xs_mobile_min}) and (max-width: #{$xs_mobile_max}) {
		@content;
	}
}
@mixin phoneLandscape {
	@media (orientation: landscape) and (max-height: $xs_mobile_max) and (max-width: $xs_mobile_max) {
		@content;
	}
}
@mixin tablet {
	@media (min-width: #{$md_tablet_min}) and (max-width: #{$md_tablet_max}) and (orientation: portrait) {
		@content;
	}
}
@mixin tabletLandscape {
	@media (min-width: #{$md_tablet_min}) and (max-width: #{$md_tablet_max}) and (orientation: landscape) {
		@content;
	}
}
@mixin responsive {
	@media (max-width: #{$xs_mobile_max}), (max-width: #{$md_tablet_max}) and (orientation: portrait) {
		@content;
	}
}
@mixin responsiveLandscape {
	@media (max-width: #{$xs_mobile_max}), (max-width: #{$md_tablet_max}) and (orientation: landscape) {
		@content;
	}
}
@mixin desktop {
	@media (min-width: #{$lg_desktop_small_min}), (min-width: #{$md_tablet_min}) and (orientation: landscape) {
		@content;
	}
}
@mixin desktopSmall {
	@media (min-width: #{$lg_desktop_small_min}) and (max-width: #{$lg_desktop_small_max }) {
		@content;
	}
}
@mixin desktopMedium {
	@media (min-width: #{$xl_desktop_medium_min}) and (max-width: #{$xl_desktop_medium_max }) {
		@content;
	}
}
@mixin desktopLarge {
	@media (min-width: #{$xxl_desktop_large}) {
		@content;
	}
}

@mixin touch {
	@media (hover: none) {
		@content;
	}
}

@mixin safari {
	@media not all and (min-resolution:.001dpcm) {
		@supports (-webkit-appearance: none) {
			@content;
		}
	}
}

@mixin mobileSafari {
	@media not all and (min-resolution:.001dpcm) {
		@supports (-webkit-appearance: none) {
			@media (max-width: #{$xs_mobile_max}), (max-width: #{$md_tablet_max}) and (orientation: portrait) {
				@content;
			}
		}
	}
}