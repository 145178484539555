@import "../../styles/variables.scss";

@function calc-right($ratio) {
	@return calc((((100vw - 2rem) * 0.6) - 1rem) * #{$ratio});
}

@function calc-left($ratio) {
	@return calc(((100vw - 2rem) * 0.4) * #{$ratio});
}

.neighborhood_page {
	width: 100%;	
	background: white;

	@include responsive {
		padding-top: var(--header-height);
	}

	.section_one_container {
		z-index: 118;
		position: fixed;
		background: white;
		height: calc((var(--initial-vh, 1vh) * 100) - var(--header-height));
		width: 100%;
		top: .5em;
		.image_container{
            // height:100vh;
			display: flex;
			width: 100%;
			.image{
				max-width: 100%;
				min-width: 100%;
				height:calc(#{$vh} * 100);
				display: inline-block;
				object-fit:cover;
				
				@include responsive {
					height: calc(((var(--initial-vh, 1vh) * 100) - var(--header-height)) * 0.4);
				}

				@include tablet {
					height: calc(((var(--initial-vh, 1vh) * 100) - var(--header-height)) * 0.5);
				}
			} 
	   }
	   .mobile-only {
		   display: flex;
			align-items: flex-end;
			padding: 1rem;
	   }
	   @include responsive {
		//    height: 100vh;
			position: relative;
			top: 0;
	   }
	}
	@include phones{
		top: 5.5em !important;
		// .section_one_container {
		// 	height: calc(100vh - 5.5em);
		// }
	}
	@include desktopSmall{
		top: .25rem !important;
	}	
	
	.section_one_plx {
		@include responsive {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
	.neighborhood_content {
		z-index: 120;
		position: relative;    
		width: 100%;
		height: 100% !important;
		top: calc(#{$vh} * 120);
		background: transparent;
		pointer-events: none;

		@include responsive {
			top: 0;
		}

		.nb_section_two{
			position: relative;
			z-index: 2;
			pointer-events: all;
			#header_text{
				padding:1rem
			}
			@include responsive{
				display: none;
				background:white !important;
				padding-bottom: 0 !important;
			}
			@include phones{
				#header_text{
					padding:1em 
				}
			}
		}
		.nb_section_three_container {
			padding: 1rem 1rem;
			position: relative;
			z-index: 100;
			background-color: white;
			display: flex;
			flex-direction: row;
			pointer-events: all;
			transform: translate3d(0, 0, 0);

			@include responsive {
				display: block;
			}

			.col {
				&:nth-child(1) {
					width: 40%;
					display: flex;
					flex-direction: column;

					@include responsive {
						width: auto;
					}

					.image {
						&:nth-child(1) {
							height: calc(#{calc-right(0.6664981949)} + #{calc-right(0.6666666667)} + #{calc-left(0.6656914894)} + 2rem);
							padding-top: calc(#{calc-right(0.6664981949)} + 1rem);

							@include responsive {
								height: auto;
								padding-top: 0;
							}
						}
						
						&:nth-child(2) {
							height: calc(#{calc-right(0.8820697954)} + #{calc-right(0.1564380265)} + 1rem);

							@include responsive {
								height: auto;
							}
						}

						&:nth-child(3) {
							flex-grow: 1;
						}
					}
				}

				&:nth-child(2) {
					width: 60%;
					padding-left: 1rem;

					@include responsive {
						width: auto;
						padding-left: 0;
					}

					.image {
						&:nth-child(1) {
							height: calc(#{calc-right(0.6664981949)} + #{calc-left(0.6656914894)} + 1rem);

							@include responsive {
								height: auto;
							}
						}

						&:nth-child(3) {
							img {
								height: #{calc-right(0.8819253911)};
								object-fit: cover;

								@include responsive {
									height: auto;
								}
							}
						}

						&:nth-child(5) {
							img {
								height: #{calc-right(0.5054151625)};
								object-fit: cover;

								@include responsive {
									height: auto;
								}
							}
						}

						&:last-child {
							@include responsive {
								margin-bottom: 0;
							}
						}
					}
				}
			}

			.image {
				margin-bottom: 1rem;

				img {
					width: 100%;
					position: sticky;
					top: var(--header-height);

					@include responsive {
						position: static;
					}
				}
			}
		}
	}
	.nb_filter_container{
		width:100%;
		height:5rem;
		background: white;
		display: flex;
		justify-content: flex-end;
		align-items:center;
		// cursor: pointer;
		z-index: 20;
		pointer-events: all;
		position: sticky;
		top: var(--header-height);
		.filter_name{
			display: inline-block;
			text-transform: capitalize;
			// transform: translateX(-10px);
			transition: all 200ms ease-in-out;
			margin: 0 var(--padding-size, 1rem) 0 0;
			padding: 1rem 0;
			letter-spacing: 0;
			color: $grey;
			cursor: pointer;
			opacity: 0.6;
		}
		.filter_selected{
			opacity: 1;
		}
	}
	#nb_map {
		width: 100%;
		pointer-events: all;
		height: calc((#{$vh} * 100) - 5rem);

		.nb_map_container {
			height: calc((#{$vh} * 100) - var(--header-height) - 5rem);
			position: sticky;
			top: calc(5rem + var(--header-height));
			width: 100%;
			display: flex;
			z-index: 1;
		}
	   
	   @include responsive {
		   position: relative;
	   }
	}

	.mapboxgl-map {
		width: 100%;
		height: 100%;
	}
}
