@import "../variables";

.home_nav{
	background: #ffffff00!important;

	.icon-nav {
		&__line {
			background-color: white !important;
		}
	}

	.branding {
		color: white;
	}

	.nav{
		.nav__elem{
			color:white !important
		}
		.nav__elem:active , .nav__elem:hover {
			color:white !important
		}
	}
	.burger.hide-mobile{
		display: flex;
	}
}
.header--main {
	@include phoneLandscape {
		display: none;
	}
}
.inner {
	position: fixed;
	z-index: 500;
	display: flex;
	align-items: flex-start;
	padding: var(--padding-size);
	height: var(--header-height);
	background-color: white;

	.header--main & {
		width: 100%;
	}
}

.branding {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 306;
	margin: 1rem;
	color: $grey;
	line-height: 0.8 !important;
	text-align: right;
	letter-spacing: -1px;

	.building-number {
		display: block;
		letter-spacing: -6px;
	}

	.hillrose {
		color: $red;
	}

	.street-number {
		letter-spacing: -3px;
	}
}

.small_navigation{
	order: 1;
	z-index: -1;
	-webkit-transform: translateY(0%);
	transform: translateY(0%);
	font-size: 1rem;
	color: $grey;
	line-height: 1rem;
	// .nav__elem:nth-child(1) {
	// 	margin-left: 1rem;
	// }
	.nav__elem:active , .nav__elem:hover {
		color: $grey;
    }
	&__elem {
		display: inline-block;
		text-transform: capitalize;
		opacity: 1;
		-webkit-transform: translateX(-10px);
		transform: translateX(-10px);
		-webkit-transition: all 200ms ease-in-out;
		transition: all 200ms ease-in-out;
        margin: 0 1rem 0 0 ;
		padding: 0;
		font-weight: $body-font-weight;
		font-family: neuzeit-grotesk, sans-serif;
		letter-spacing: 0;
		font-size: 1em;
		color: $grey;
	}
}
.nav {
    order: 1;
	z-index: -1;
	-webkit-transform: translateY(0%);
	transform: translateY(0%);
	font-size: 1rem;
	color: $grey;
	line-height: 1rem;
	// .nav__elem:nth-child(1) {
	// 	margin-left: 1rem;
	// }
	.nav__elem:active , .nav__elem:hover {
		color: $grey;
	}
	&--home {
		.nav__elem {
			-webkit-transition: all 200ms ease-in-out;
			transition: all 200ms ease-in-out;
		}
	}
	&__elem {
		transition: none;
		display: inline-block;
		text-transform: capitalize;
		opacity: 0;
		-webkit-transform: translateX(-10px);
		transform: translateX(-10px);
        margin: 0 1rem 0 0 ;
		padding: 0;
		font-weight: $body-font-weight;
		font-family: neuzeit-grotesk, sans-serif;
		letter-spacing: 0;
		font-size: 1em;
		color: $grey;
	}

	&.main_nav {
		.nav__elem {
			opacity: 0.6;

			&.nav__active {
				opacity: 1;
			}
		}
	}


	&--mobile {
		z-index: 999;
		position: fixed;
		top: 0;
		left: calc(-100vw + 4rem);
		width: calc(100vw - 4rem);
		height: calc(#{$vh} * 100);
		background-color: $white;
		display: flex;
		flex-direction: column;
		padding: 3px 6px;

		&.slide {
			.link {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
		}
		
		.link {
			text-transform: uppercase;
			padding: 0.25rem;
			color: $grey;
			line-height: 1;
			opacity: 0;
			transition: all 400ms;
			transform: translate3d(0, 2.6rem, 0);

			@for $i from 1 through 9 {
				&:nth-child(#{$i}) {
					transition-delay: calc(#{$i} * 75ms + 50ms);
				}
			}

			&--call {
				margin-top: auto;
				color: $red;
				transition: none;
				opacity: 1;
				transform: translate3d(0, 0, 0);

				@include tablet {
					display: none;
				}
			}
		}
	}
}

.nav_inactive {
	z-index: -100;
	width: auto;
    position: absolute;
    cursor: not-allowed;
    pointer-events: none;
	left: 0;
	margin-left: 4em;
}

.nav_active {
	z-index: 10;
	visibility: visible ;
	z-index: 2 ;
	background: #ffffff00 ;
	width: auto;
	position: relative;
	// left: -1rem;
	.nav__elem {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		transition-delay: 140ms;
		text-transform: capitalize !important;
	}
	// .nav__elem:nth-child(1) {
	// 	transition-delay: 75ms;
	// }
	// .nav__elem:nth-child(2) {
	// 	transition-delay: 150ms;
	// }
	// .nav__elem:nth-child(3) {
	// 	transition-delay: 225ms;
	// }
	// .nav__elem:nth-child(4) {
	// 	transition-delay: 300ms;
	// }
	// .nav__elem:nth-child(5) {
	// 	transition-delay: 375ms;
	// }
	// .nav__elem:nth-child(6) {
	// 	transition-delay: 450ms;
	// }
	// .nav__elem:nth-child(7) {
	// 	transition-delay: 525ms;
	// }
	// .nav__elem:nth-child(8) {
	// 	transition-delay: 600ms;
	// }
	// .nav__elem:nth-child(9) {
	// 	transition-delay: 675ms;
	// }
}
// .ant-drawer-body{
// 	padding:0 !important;
// 	position: absolute;
// 	left:1em ;
// 	top:1em ;
// }
// .ant-drawer-content {
// 	background: #ddd !important;
// 	color: #969696;
// 	.nav__elem {
// 		display: contents;
// 		opacity: 1;
// 		font-family: futura-pt, sans-serif;
// 		font-size: 2.1em !important;
// 		text-transform: uppercase !important;
// 		line-height: 1.5;
// 		font-weight: 300;
// 		font-style: normal;
// 		color: #969696;
// 	}
// }
// .ant-drawer-left.ant-drawer-open,
// .ant-drawer-right.ant-drawer-open {
// 	display: none;
// 	@include phones {
// 		display: block;
// 	}
// }
// .mobile_menu{
// 	@include tablet {
// 		display: none;
// 	}
// }


////// BURGER BUTTON STYLES //////

.burger {
	display: flex;
	order: 0;
	font-weight: normal;
	font-size: 0;
	width: 3rem;
	height: 3rem;

	&.hide-mobile {
		display: none;
	}
}
.hidden{
	display: none !important;
}
// .small_navigation{
// 	width:100%;
// 	display: flex;
// 	margin-left: 0rem;
// 	justify-content: flex-start;
// 	align-items:center;
// 	cursor: pointer;
// 	.nav_item{
// 		display: inline-block;
// 		text-transform: capitalize;
// 		transform: translateX(-10px);
// 		transition: all 200ms ease-in-out;
// 		margin: 0 1rem 0 0;
// 		padding: 0;
// 		font-weight: 300;
// 		font-family: neuzeit-grotesk, sans-serif;
// 		letter-spacing: 0;
// 		font-size: 1em;
// 		color: white;
// 	}
	
// }

.icon-nav {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	// @include phones {
	// 	width: 1.5rem;
	// 	height: 1.2rem;
	// }
	&__line:nth-child(1) {
		margin-top: 0px !important;
	}
	&__line {
		position: relative;
		top: auto;
		bottom: auto;
		display: block;
		background-color: $grey;
		opacity: 1;
		-webkit-transition: all 0.3s ease-out;
		-webkit-transform-origin: center;
		transition: all 0.3s ease-out;
		transform-origin: center;
		height: 0.12rem;
		margin: 0 0 0.7rem;
		// @include phones {
		// 	height: 0.06rem;
		// 	margin: 0 0 0.4rem;
		// }
    }
  
}
.icon_nav_active {
	:nth-child(1) {
		transform: translateY(0.6rem) rotate(45deg);
	}
	:nth-child(2) {
		opacity: 0;
	}
	:nth-child(3) {
		transform: translateY(-1.05rem) rotate(-45deg);
	}
	// @include phones {
	// 	:nth-child(1) {
	// 		transform: translateY(0.45rem) rotate(45deg);
	// 	}
	// 	:nth-child(2) {
	// 		opacity: 0;
	// 	}
	// 	:nth-child(3) {
	// 		transform: translateY(-0.45rem) rotate(-45deg);
	// 	}
	// }
}
