@import "../../styles/variables.scss";
.team-page {
	position: relative;
	// height: calc(#{$vh} * 100);
	background: #f4f4f4;
	padding-top: var(--header-height);
	display: flex;
	flex-direction: column;
	.mainContent {
		position: relative;
		z-index: 1;
		height:auto;
		right: 0;
		padding: 1rem;
		width: 100%;
		height: auto;
		background: #f4f4f4;
		.ant-list {
			font-size: 1em !important;
			margin-top: 1rem;
			.ant-list-item{
				padding:0  !important
			}
		}
		.ant-list-split .ant-list-item {
			border-bottom: 0;
		}
		@include phones {
			// top: 5.5em;
			height: auto;
			padding: 1em 1em 1em;
			.ant-list {
				font-size: 1em !important;
				margin-top: 1em;
			}
		}
		@include responsive {
			
			width: 100%;
		}
		h3 {
			margin-bottom: 0 !important;
			text-align: right;
			margin: 0 0;
		}
		h3 span {
			color: $red;
			font-weight: 200;
		}
		.submit-btn {
			// float: right;
			margin: 20px 0;
			height: 3em;
			line-height: 1em;
			color: $grey;
			background: transparent;
			border: 1px solid $grey !important;
			border-radius: 0px;
			font-size: 1em;
			text-shadow: 0 -1px 0 rgba(0, 0, 0, 0) !important;
			-webkit-box-shadow: none !important;
			box-shadow: none !important;
			&::after {
				animation: none !important;
			}
			@include phones {
				font-size: 1em !important;
			}
		}
		.submit-btn:hover,
		.submit-btn:focus {
			background: transparent; //$grey !important;
			border-color: $grey !important;
			color: $grey;
		}
		.list_description {
			padding: .5em 0;
			// font-weight: $body-font-weight;
			text-align: right;
			// font-family: neuzeit-grotesk, sans-serif;
			// font-size: 1em;
			// float: right;
			margin-left: auto;
			width: 50%;
			@include responsive {
				width: 100%;
			}

			@include tabletLandscape {
				width: 75%;
			}
		}
		.author {
			text-align: right;
			color: $grey;
			margin-bottom: 0;
		}
	
		.ant-list-vertical .ant-list-item-action > li {
			padding: 0 !important;
			width: 100%;
		}

		.sop-container {
			text-align: right;
			padding-top: 0.5rem;
		}

		.sop {
			display: inline-block;
			color: inherit;
			text-decoration: underline;
		}
	}
	.demo-infinite-container {
		border: 1px solid #e8e8e8;
		border-radius: 4px;
		overflow: auto;
		padding: 8px 24px;
		height: 300px;
	}

	footer {
		margin-top: auto;
	}
}
