@import "../../styles/variables.scss";
.press-page {
	position: relative;
	background: #f4f4f4;
	.mainContent {
		position: relative;
		z-index: 1;
		padding: calc(var(--header-height) + 1rem) 1rem 1rem;
		width:100%;
		background: #f4f4f4;
		.article_container{
			width:50%;
			max-width: 980px;
			margin-left: auto;
		}
		.ant-list {
			font-size: 1rem !important;
		}
		.ant-list-split .ant-list-item {
			border-bottom: 0;
			padding:1rem 0 0  !important;
		}
		@include responsive {
			width: 100%;
			.ant-list-split .ant-list-item {
				border-bottom: 0;
				padding: 0 0  !important;
			}
			.article_container{
				width:100%;
				margin-bottom:1rem
			}
		}
		@include tabletLandscape {
			.article_container {
				width: 75%;
			}
		}
		h3 {
			margin-bottom: 0 !important;
			line-height: 0.9 !important;
			font-family: futura-pt, sans-serif;
			font-weight: 300;
			font-size: 2.6rem;
			text-transform: uppercase;
			color: $red;			
			text-align: right;
			line-height: 2rem;
			font-weight: 200;
			// @include phones {
			// 	font-size: 1.9rem;
			// }

			@include tablet {
				font-size: 3rem;
			}

			@include phones {
				font-size: 2.5rem;
			}
		}
		.submit-btn {
			float: right;
			margin: 1rem 0 0;
			height: 3rem;
			line-height: 1rem;
			color: $grey;
			background: transparent;
			border: 1px solid $grey !important;
			border-radius: 0px;
			font-size: 1rem;
			text-shadow: 0 -1px 0 rgba(0, 0, 0, 0) !important;
			-webkit-box-shadow: none !important;
			box-shadow: none !important;
			&::after {
				animation: none !important;
			}
			@include phones {
				font-size: 1em !important;
			}
		}
		.submit-btn:hover,
		.submit-btn:focus {
			background: transparent; //$grey !important;
			border-color: $grey !important;
			color: $grey;
		}
		.list_description {
			font-weight: $body-font-weight;
			text-align: right;
			font-family: neuzeit-grotesk, sans-serif;
			font-size: 1rem;
			float: right;
			line-height: 1;
			margin-top:.5rem;
			@include phones {
				width: 100%;
				font-size: 1.2rem;
				line-height: 1.4rem;
			}
		}
		.author {
			text-align: right;
			color: $grey;
			margin: 0;
		}
	
		.ant-list-vertical .ant-list-item-action > li {
			padding: 0 !important;
			width: 100%;
		}
	}
	.demo-infinite-container {
		border: 1px solid #e8e8e8;
		border-radius: 4px;
		overflow: auto;
		padding: 8px 24px;
		height: 300px;
	}
}
