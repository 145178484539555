@import "../../styles/variables.scss";
.article-page {
    position: relative;
    background: #f4f4f4;
    height: calc(#{$vh} * 100);
    .mainContent {
        position: absolute;
		top: 0;
		right: 0;
		padding: 9rem 1rem 1rem;
        width: 100%;
        .articleContainer{
            // width: 50%;
            // float: right;
            h3 {
                margin-bottom: 0 !important;
                // line-height: 0.9 !important;
                // font-family: futura-pt, sans-serif;
                // font-weight: 300;
                // font-size: 2.6rem;
                // text-transform: uppercase;
                color: $grey;
                text-align: right;
                // line-height: 1;
                // @include phones {
                //     font-size: 2.5rem;
                //     line-height: 1;
                // }
                // @include tablet {
                //     font-size: 3rem;
                // }
            }
            h3 span {
                color: $red;
            }
            .description{
                padding: 1rem 0;
                font-weight: $body-font-weight;
                text-align: right;
                font-family: neuzeit-grotesk, sans-serif;
                font-size: 1rem !important;
                float: right;
                width: 40%;
                @include phones {
                    width: 100%;
                }
                .download-p{
                    padding-top:1rem;
                    .pdf-btn {
                        margin-left: 1rem;
                        cursor: pointer;
                        color: $grey;
                        border-color: #ffffff00;
                        -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0);
                        box-shadow: 0 2px 0 rgba(0, 0, 0, 0);
                        font-weight: $body-font-weight;
                        font-family: neuzeit-grotesk, sans-serif;
                    }
                    .pdf-btn:hover {
                        cursor: pointer;
                    }
                }
                
            }
        }

    }
}