@import "../../styles/variables.scss";

#residence_page {
	width: 100%;
	height: calc(#{$vh} * 300);
	position: relative;
	background: white;

	@include responsive {
		height: 100%;
	}

	.re_section_one {
		position: relative;
		z-index: 305;
		top: var(--header-height);
		padding:0;

		#image_only {
			padding: 0;
			
			.image_container{
				.image{
					height: calc((#{$vh} * 100) - var(--header-height))
				}
			}
		}

		@include phones {
			display: none;
		}

		@include responsive {
			#image_only{
				height: auto;
				padding-bottom: 1rem;

				.image_container{
					.image{
						height: auto
					}
				}
			}
		}
	}

	.main_content_relative{
		position: relative!important;
		top: var(--header-height) !important;
	}
	
	#main_content {
		top: var(--header-height);
		position: fixed;
		width: 100%;
		z-index: 20;

		> * {
			background-color: white;
			position: relative;
			z-index: 1;
			transform: translate3d(0, 0, 0);
		}

		@include phones {
			top: var(--header-height) !important;
		}
		@include touch {
			position: relative;
		}
		.re_section_two{
			@include touch {
				display: flex;
				flex-direction: column;
			}

			@include responsive {
				height: calc((var(--initial-vh, 1vh) * 100) - var(--header-height));
			}
			
			#image_only {
				padding:0;

				@include responsive {
					height: auto;
				}

				@include touch {
					height: auto;
				}

				.image {
					height: calc((#{$vh} * 100) - var(--header-height));
				}
			}

			#header_text{
				padding:1rem;
				@include responsive {
					margin-top: auto;
					// padding:1em
				}
			}

			.carousel {
				.slide {
					img {
						height: calc((#{$vh} * 100) - var(--header-height));

						@include responsive {
							height: calc(((#{$vh} * 100) - var(--header-height)) * 0.4);
						}

						@include tablet {
							height: calc(((#{$vh} * 100) - var(--header-height)) * 0.5);
						}
					}
				}

				@include tabletLandscape {
					.control-dots {
						margin: 0;
						transform: translateY(-100%);

						.dot {
							background-color: white;
						}
					}
				}
			}
		}

		.re_section_three {
			height: calc(#{$vh} * 230);
			position: relative;

			@include responsive {
				height: calc((var(--initial-vh, 1vh) * 100) - var(--header-height));
				display: flex;
				flex-direction: column;
			}

			@include touch {
				height: calc((var(--initial-vh, 1vh) * 100) - var(--header-height));
				display: flex;
				flex-direction: column;
			}

			#image_with_description {
				position: sticky;
				top: var(--header-height);

				@include responsive {
					position: static;
				}
			}

			#image_only {
				@include responsive {
					height: auto;
				}

				@include touch {
					height: auto;
				}
			}

			#header_text {
				@include touch {
					padding: 1rem;
					margin-top: auto;
				}

				@include responsive {
					padding: 1rem;
					margin-top: auto;
				}
			}
			
			.image {
				background-position-x: 0;
				background-position-y: 50%;
				background-repeat: no-repeat;
				background-size: auto 100% !important;
				white-space: nowrap;

				@include responsive {
					height: calc(((#{$vh} * 100) - var(--header-height)) * 0.4);
					width: auto;
				}

				@include tablet {
					height: calc(((#{$vh} * 100) - var(--header-height)) * 0.5);
				}

				@include tabletLandscape {
					background-size: auto 100% !important;
					background-position-x: 5%;
				}
			}
		}
		
		.re_section_four {
			height: calc(#{$vh} * 230);
			width: 100%;
			z-index: 305;
			position: relative;
			
			// @include touch {
			// 	height: auto;
			// }

			@include responsive {
				height: calc((var(--initial-vh, 1vh) * 100) - var(--header-height));
				display: flex;
				flex-direction: column;
			}

			@include touch {
				height: calc((var(--initial-vh, 1vh) * 100) - var(--header-height));
				display: flex;
				flex-direction: column;
			}

			#image_only {
				@include responsive {
					height: auto;
				}

				@include touch {
					height: auto;
				}
			}

			#header_text {
				@include responsive {
					padding: 1rem;
					margin-top: auto;
				}

				@include touch {
					padding: 1rem;
					margin-top: auto;
				}
			}

			#image_with_description {
				position: sticky;
				top: var(--header-height);

				// @include touch {
				// 	position: static;
				// }

				@include responsive {
					// padding-top: 0;
					position: static;
				}

				.image_container{
					overflow: hidden;

					@include responsive {
						overflow: auto;
					}

					#image_only{
						background-position-x: 0;
						background-position-y: 50%;
						background-repeat: no-repeat;
						background-size: auto 100% !important;
						height: calc((#{$vh} * 100) - var(--header-height) - 2rem);
						white-space: nowrap;

						@include responsive {
							height: calc(((#{$vh} * 100) - var(--header-height)) * 0.4);
							width: auto;
						}

						@include tablet {
							height: calc(((#{$vh} * 100) - var(--header-height)) * 0.5);
						}

						@include tabletLandscape {
							background-size: auto 100% !important;
							background-position-x: 5%;
						}
					}
				}

				.carousel {
					.slide {
						.slide-container {
							height: calc((#{$vh} * 50) - (var(--header-height) / 2));
							background-size: 200% auto;

							@include phones {
								background-size: auto 100%;
							}
						}

						&:first-child {
							.slide-container {
								background-position: left center;
							}
						}

						&:last-child {
							.slide-container {
								background-position: right center;
							}
						}
					}
				}
			}
		}

		.res_section_five{
			#image_with_description{
				@include tabletLandscape {
					padding-top: 0;
				}

				.image_container{
					width: 40%;
					margin-left: auto;

					@include responsive {
						width: 100%;
					}

					@include tabletLandscape {
						height: 100%;
					}

					.image {
						object-position: right;
						width: 100%;

						@include phones {
							height: auto;
						}

						@include tabletLandscape {
							height: 100%;
						}

						@include tablet {
							height: calc(((#{$vh} * 100) - var(--header-height) - 2rem) * 0.8);
						}
					}
				}
			}
		}
	}
}
