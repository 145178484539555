@import "../../styles/variables.scss";

.penthouses-page {
	width: 100%;	
	background: white;
	position: relative;

	.section_one_container {
		z-index: 99;
		height: calc(#{$vh} * 100);
		position: fixed;
		background: white;
		height: calc((#{$vh} * 100) - var(--header-height));
		.image_container{
            height: calc(#{$vh} * 100);
            display: flex;
			.image{
				width: 100%;
				height: calc(#{$vh} * 100);
				display: inline-block;
				object-fit:cover;               
			} 
       }
	}
	
	@include responsive{
		top: var(--header-height);
		.section_one_container {
			position: relative;
			height:calc((#{$vh} * 100) - var(--header-height) - 30rem) !important;
			.image_container{
				height:auto;
				.image{
					height:auto;
					object-fit:contain;  
					object-position: top;             
				} 
		    }
		}
	}
	.penthouse_content {
		position: relative;
		width: 100%;
		height: 100% !important;
		z-index: 99;
		top: calc(#{$vh} * 120);

		> * {
			background-color: white;
			position: relative;
			z-index: 1;
			transform: translate3d(0, 0, 0);
		}

		.ph_section_two{
			.paragraph_container {
				width: 100% !important;
			}

			> #header_text {
				padding: 1rem
			}

			#image_with_description {
				@include responsive {
					height: calc((var(--initial-vh, 1vh) * 100) - var(--header-height));
				}
			}
		}

		@include responsive{
			top:0;
		}
	}
}
