@import "../variables.scss";

#section{
    // background:blue;
    width: 100%;
    height:calc((#{$vh} * 100) - 7.5em);
    padding:  0 1.5em 1.5em;
    @include phones{
        height:calc((#{$vh} * 100) - 5.5em) !important;
        padding:  0 1em 1em;
    }
    @include tablet {
        padding:  0  1.5em 1.5em;
    }

    .section_container{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: flex-end;
        flex-wrap: nowrap;
        -webkit-flex-flow: row nowrap;
        width: 100%;
        @include desktopSmall{
            .image_container{
                    .image{
                        height: calc((#{$vh} * 100) - 7.5em)!important;
                        width: 700px;
                    } 
           }
        }
        @include phones{
            height:calc((#{$vh} * 100) - 5.5em);
        }
        @include responsive{
             flex-wrap: wrap;
            -webkit-flex-flow: row wrap; 
            align-items: flex-start !important;
            height:100%;
            .image_container{
                height:auto !important;
                .image{
                    height:auto !important;
                    width: 100%;
                    align-self: flex-start !important
                } 
            }
        }
        .image_container{
            height:calc((#{$vh} * 100) - 9em);
            display: flex;
                .image{
                    height: 100%;
                    background-position: center;
                    display: inline-block;
                    object-fit: cover;   
                    object-position: bottom;                 
                    align-self: flex-end;
                } 
       }
       .paragraph_container{
            align-self: flex-end;
            text-align: right;
            width: 100% !important;
            p{
                float: right;
                text-align: right;
                margin-bottom: 0;
                width: 80%;
                font-family: neuzeit-grotesk, sans-serif;
				font-style: normal;
				font-weight: $body-font-weight;
				line-height: 1em;
				letter-spacing: 0;
				color: $grey;
            }
            @include phones{
               p{
                width: 75%;
               }
            }
            @include desktopSmall{
                p{
                 width: 85%;
                }
             }
            @include tablet{
                p{
                 width: 65%;
                }
             }
         }
    }
}
// #text_section {
//     display: inline-block;
//     background: white;
//     right: 0;
//     width: 100%;
//     text-align: right;
//     h3:first-child {
//         color: $red;
//     }
//     h3 {
//         text-transform: uppercase;
//         font-family: futura-pt, sans-serif;
//         letter-spacing: 0;
//         font-weight: 300;
//         color: $grey;
//         line-height: .9 !important;		
//         font-size: 3em;
//         font-weight: 300 !important;
//         margin-bottom: 0px;
//         @include responsive {
//             font-size: 2.35em;
//         }
        
//     }
//     p {
//         float: right;
//         text-align: right;
//         color: $grey;
//         font-size: 1em;
//         width: 20%;
//         margin-bottom: 0;
//         @include phones{
//             width: 80%;
//             margin: .5em 0 0;
//         }
//         @include tablet {
//             width: 55%;

//         }
//         @include desktopMedium {
//             width: 40%;
//         }
//     }
// }
