@import "~antd/dist/antd.css";
@import "../styles/variables.scss";
body,
html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// overflow: scroll;
}

::-webkit-scrollbar {
	width: 0px;
	background: transparent; /* make scrollbar transparent */
}

.mobile-only {
	@include desktop {
		display: none !important;
	}
}

.hide-mobile {
	@include responsive {
		display: none !important;
	}
}

body.nav-open {
	overflow: hidden;
}

.slide {
	transform: translate3d(calc(100vw - 4rem), 0, 0);
}

.slidable {
	transition: transform 400ms;
}

.page-layout {
	max-width: none;
}

.home-page {
	max-width: none;
}

.navigation {
	max-width: none;

	.grey-nav {
		max-width: none;
	}
}

.nobr {
	white-space: nowrap;
}

.carousel {
	.slide {
		transform: none;

		img {
			height: calc((#{$vh} * 50) - (var(--header-height) / 2));
			object-fit: cover;
		}
	}

	.control-dots {
		position: relative;
		text-align: right;
		padding-right: 0.5rem;
		margin: 0.5rem 0;

		.dot {
			box-shadow: none;
			background-color: $grey;
			margin: 0.25rem;
		}
	}
}

footer {
	position: sticky !important;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1rem;
	background-color: $white !important;
	font-size: 0.7rem;
	z-index: 0 !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: left;
	
	&.light {
		background-color: white !important;
	}

	.links {
		a {
			color: inherit;
			text-decoration: underline;
			margin-right: 1em;
		}
	}

	.next {
		margin-left: auto;
		line-height: 1;

		@include phones {
			display: none;
		}

		* {
			vertical-align: middle;
		}

		a {
			color: inherit;
			font-size: 1rem;
			padding: 1rem 0.5rem;
			text-decoration: none;
			font-family: neuzeit-grotesk, sans-serif;
			text-transform: capitalize;
			display: inline-block;
		}
	}
}

.nowrap {
	white-space: nowrap;
}

:root {
	--header-height: 6rem;
	--padding-size: 1rem;
	--vh: 1vh;

	@include phones {
		font-size: 12px !important;
	}

	@include phoneLandscape {
		--header-height: 0;
	}
	
	@include tablet {
		--header-height: 7rem;
		font-size: 16px !important;
	}

	@include tabletLandscape {
		--header-height: 7rem;
	}
	
	@include desktopSmall {
		font-size: 13px !important;
	}
	
	@include desktopMedium {
		font-size: 15px !important;
	}
	
	@include desktopLarge {
		font-size: 17px !important;
	}
}

.heading, h3.heading {
	font-family: 'futura-pt', sans-serif;
	font-size: 2.6rem;
	line-height: 0.8;
	font-weight: 300;
	text-transform: uppercase;
	color: inherit;

	@include phones {
		font-size: 2.5rem;
	}

	@include tablet {
		font-size: 3rem;
	}

	@include tabletLandscape {
		font-size: 3rem;
	}
}

body {
	font-size: 1rem;
	font-family: neuzeit-grotesk, sans-serif;
	font-weight: $body-font-weight;
	line-height: 1rem;
	color: $grey;

	@include phones {
		font-size: 1.2rem;
		line-height: 1.4;
	}
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
	opacity: 1;

	&, &:hover {
		background-color: transparentize($grey, 0.5);
	}
}