@import "../../styles/variables.scss";

#header_text{
    text-align: right;
    background: white;
    display: inline-block;
    width: 100%;

    h3:first-child {
        color: $red;
    }

    h3 {
        margin-bottom: 0px;

        @include phones {
            margin-left: -1rem;
            margin-right: -0.1rem;
        }
    }

    .description {
        display: inline-block;
        text-align: right;
        background: white;
        width: 40%;
        margin-bottom: 0;
        padding-top: .5rem;

        @include tablet {
            width: 65%;
        }

        @include phones {
            width: 100%;
        }
    }
}

#image_with_description {
    height: calc((#{$vh} * 100) - var(--header-height));
    width: 100%;
    padding: var(--padding-size);
    display: flex;

    @include responsive {
        flex-direction: column;
        justify-content: space-between;
    }

    &.align-left {
        .image_container {
            order: 2;

            @include responsive {
                order: 1;
            }
        }

        .paragraph_container {
            order: 1;

            @include responsive {
                order: 2;
            }

            p {
                text-align: left;
                padding-left: 0;
                padding-right: var(--padding-size);

                @include responsive {
                    text-align: right;
                    padding-right: 0;
                }
            }
        }
    }

    .image_container {
        order: 1;
        width: 75%;
        align-self: flex-end;

        @include responsive {
            width: 100%;
            align-self: flex-start;
        }

        .image {
            max-width: 100%;
            min-width: 100%;
            height:  calc((#{$vh} * 100) - var(--header-height) - 2rem);
            object-fit: cover;
            
            @include tablet {
                height: calc(((#{$vh} * 100) - var(--header-height)) * 0.5);
            }

            @include phones {
                height: calc(((#{$vh} * 100) - var(--header-height)) * 0.4);
            }
         } 
    }
    
    .paragraph_container {
        order: 2;
        max-width: 100%;
        align-self: flex-end;
        width: 25%;

        @include tablet {
            width: 65%;
            margin-left: auto;
        }

        @include phones {
            width: 100%;
        }

        p {
            align-self: flex-end;
            text-align: right;
            padding-left: var(--padding-size);
            margin-bottom: 0;
        }
    }

    #header_text {
        padding: 0;
    }
}

#image_only{
    background: white;
    height: calc((#{$vh} * 100) - var(--header-height));
    width: 100%;
    padding:  0 ;
    .image_container{
        order:1;
        width:100%;
        align-self: flex-end;   
        .image{
            max-width: 100%;
            min-width: 100%;
            height:  calc((#{$vh} * 100) - 9em);
            object-fit: cover;  
         } 
    }
    @include responsive{
        padding:   0 ;
        .image_container{
           width: 100%;
           align-self: flex-start;
           .image{  height: auto;}  
        }
      
     }
     @include desktopSmall{
        .image_container{
            align-self: flex-start;
            .image{  height: auto;}  
         }
     }
}