@import "../../styles/variables.scss";
.amenities_page {
	width: 100%;
	background: white;
	.mainContent {
		position: relative;
		width: 100%;
		background: white;
		top: var(--header-height);
		height: calc(#{$vh} * 300);

		@include responsive {
			height: auto;
		}

		> * {
			background-color: white;
			position: relative;
			z-index: 1;
			transform: translate3d(0, 0, 0);
		}

		.amen_section_one_header{
			padding: 0 0 0 ;
			background: white;
			width: 100%;
			z-index: 305;
			position: relative;			
			#image_only{
				background-position: 0 0;
				background-repeat:none;
				background-size:cover!important;
				
				@include tablet {
					height: calc(((var(--initial-vh, 1vh) * 100) - var(--header-height)) * 0.5);
				}

				@include phones {
					height: calc(((var(--initial-vh, 1vh) * 100) - var(--header-height)) * 0.4);
				}

				.image_container {
					height: 100%;
				}

				.image {
					height: 100%;
				}
			}		
		}
		
		.amen_header_section{
			padding: 1rem;

			@include phones {
				height: calc(((var(--initial-vh, 1vh) * 100) - var(--header-height)) * 0.6);
				display: flex;
				align-items: flex-end;
			}

			@include tablet {
				height: calc(((var(--initial-vh, 1vh) * 100) - var(--header-height)) * 0.5);
				display: flex;
				align-items: flex-end;
			}
		}
		.amen_section_one{
			#image_with_description{
			padding-top:0;
			@include desktopSmall{
					.image_container{
						.image{  
							height: calc((#{$vh} * 80) - var(--header-height) - 2rem) !important;
							}  
						}
				}	
			}	
		}	
		.amen_section_two{
			#image_with_description{
				flex-wrap: wrap;
				-webkit-flex-flow: row wrap;
				flex-flow: row wrap;
				height: auto;
				min-height: calc((#{$vh} * 100) - var(--header-height));
				.image_container{
					width: 100%;
					align-self: flex-start;
					.image{
						max-width: 100%;
						height:  calc((#{$vh} * 90) - var(--header-height) - 2rem) ;
						object-position: bottom
					 } 
				}
				.paragraph_container{
					width: 100%;
					padding-top: var(--padding-size);

					p {
						text-align:left;
						width: 40%;
						padding: 0;

						@include phones {
							width: 100%;
							text-align: right;
						}

						@include tablet {
							width: 65%;
						}
					}
				}
			}
			@include desktopSmall{
				#image_with_description{
					.image_container{
						.image{
							height:  auto ;
						 } 
					}
				}
			}
			@include phones {
				#image_with_description{
					.image_container{
						.image{
							height: calc(((#{$vh} * 100) - var(--header-height)) * 0.4);
						 } 
					}
				}
			}
			@include tablet {
				#image_with_description{
					.image_container{
						.image{
							height: calc(((#{$vh} * 100) - var(--header-height)) * 0.5);
						 } 
					}
				}
			}
		}
		.first_animation{
			padding: 0 0 1rem ;
			background: white;
			// height:80%;
			height:calc((#{$vh} * 100) - var(--header-height));
			width: 100%;
			z-index: 305;
			position: relative;
			#image_only{
				position: sticky;			
				background-position: 0 0;
				background-repeat:none;
				background-size:cover!important;
				height:calc((#{$vh} * 100) - var(--header-height)) !important;

				@include responsive {
					display: none;
				}

				@include touch {
					display: none;
				}
			}

			@include responsive {
				height: auto;
				padding-bottom: 0;
				overflow: auto;
			}

			@include touch {
				height: auto;
				padding-bottom: 0;
				overflow: auto;
			}
		
			// @include touch {
			// 	height: auto;
			// }

			img {
				width: 100%;
				height: calc((#{$vh} * 100) - var(--header-height));
				object-fit: cover;

				@include responsive {
					height: calc(((#{$vh} * 100) - var(--header-height)) * 0.4);
				}

				@include tablet {
					height: calc(((#{$vh} * 100) - var(--header-height)) * 0.5);
				}

				@include phoneLandscape {
					height: calc(#{$vh} * 100);
				}

				// @include desktop {
				// 	display: none;
				// }

				// @include touch {
				// 	display: block;
				// }
			}
			
			.carousel {
				.slide-container {
					height: calc((#{$vh} * 100) - var(--header-height));
				}

				.slide {
					

					&:first-child {
						.slide-container {
							background-position: left center;
						}
					}

					&:last-child {
						.slide-container {
							background-position: right center;
						}
					}
				}
			}
		}
		.last_animation{
			padding: 0;
			height: calc(#{$vh} * 250);
			width: 100%;
			z-index: 305;
			position: relative;
			background-color: transparent;

			@include safari {
				padding-bottom: $footer_height;
			}

			img {
				width: 100%;
				margin-bottom: 1rem;
			}
		
			@include responsive {
				height: auto;
				padding-bottom: 0 !important;
			}

			@include responsive {
				height: auto;
			}

			#image_with_description {
				background-color: white;
				position: sticky;
				top: var(--header-height);
				// padding:0 1rem 1rem;

				.image_container{
					#image_only{
						background-position-x: 0 ;
						background-position-y:0 ;
						background-repeat:none;
						background-size:cover !important;
						height: calc((#{$vh} * 100) - var(--header-height) - 2rem) !important;

						@include responsive {
							margin-bottom: 1rem;
						}

						@include tabletLandscape {
							// width: calc(((#{$vh} * 100) - var(--header-height) - 2rem) * 0.7135416667);
						}
					}
				}

				@include responsive {
					position: relative;
					top: 0;
					height: auto;
				}

				// @include touch {
				// 	position: relative;
				// 	top: 0;
				// 	height: auto;
				// }
			}

			.carousel {
				.slide {
					&:first-child {
						.slide-container {
							background-position: top center;
						}
					}

					&:last-child {
						.slide-container {
							background-position: bottom center;
						}
					}

					.slide-container {
						height: calc((#{$vh} * 50) - (var(--header-height) / 2));
						background-size: 110% auto;

						@include phones {
							background-size: auto 230%;
						}
					}
				}
			}
		}
	}
}
