@import "variables";
.contact-container {
	background: $white;
	// top: 0;
	// left: 0;
	// right: 0;
	min-height: calc(#{$vh} * 100);
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	@include mobileSafari {
		min-height: calc(var(--initial-vh, 1vh) * 100);
	}
}
.mainContent{
	.ant-row{
		position: static;
	}
}
.form-row-container {
	height: 100%;
	@include phones {
		position: absolute;
		bottom: 0;
	}
}
.ant-row-flex-bottom {
	-ms-flex-align: end;
	align-items: flex-end;
	@include phones {
		align-items: flex-end !important;
	}
}

.ant-form-explain {
	display: none;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
	background-color: inherit !important;
}

.contact-form-container {
	padding: 1rem;
	background: #f4f4f4;
	margin-left: auto;
	bottom: 0;
	right: 0;
	width: 50%;
	text-align: right;

	.links {
		display: flex;
		flex-direction: column;

		// &--short {
		// 	display: none;

		// 	@include phones {
		// 		display: flex;
		// 	}
		// }

		// &--full {
		// 	@include phones {
		// 		display: none;
		// 	}
		// }
	}

	.copy {
		margin-bottom: 1rem;

		&--last {
			margin-bottom: 0;
		}

		&--short {
			display: none;

			@include phones {
				display: block;
			}

			@include tabletLandscape {
				display: block;
			}
		}

		&--full {
			@include phones {
				display: none;
			}

			@include tabletLandscape {
				display: none;
			}
		}
	}

	.heading {
		color: $red;
		margin-left: auto;
		margin-top: 1.5rem;
	}

	.inputs {
		padding: 1rem 0;
		width: 70%;
		margin-left: auto;

		@include responsive {
			width: auto;
		}

		@include tabletLandscape {
			width: 66.66%;
		}

		> .ant-row, > .ant-row-flex {
			padding: 1rem 0;
		}
	}

	.ant-form-item {
		font-size: inherit;
		line-height: inherit;
	}

	.ant-form-item-control {
		line-height: inherit;
	}

	.ant-select {
		.ant-select-selection {
			outline: none;
			box-shadow: none;
			background-color: transparent;
			border-left: none;
			border-top: none;
			border-right: none;
			border-bottom: 1px solid #707070ad;
			border-radius: 0;

			.ant-select-selection__rendered {
				margin: 0;
				text-align: right;
				line-height: 1;
				padding: 0.75rem 0;
				font-family: neuzeit-grotesk, sans-serif;

				&::after {
					display: none;
				}

				.ant-select-selection-selected-value {
					float: none;
					font-size: 1rem;

					@include phones {
						font-size: 1.2rem;
					}
				}
			
				.ant-select-selection__placeholder {
					position: static;
					text-align: right;
					margin-top: 0;
					height: auto;
					line-height: inherit;
					color: inherit;
					font-size: 1rem;

					@include phones {
						font-size: 1.2rem;
					}
				}
			}

			.ant-select-arrow {
				right: auto;
				left: 0;
				color: inherit;
			}
		}
		
		.ant-select-selection--single {
			height: auto;
		}
	}

	.broker-col {
		text-align: right;
		white-space: nowrap;

		.ant-form-item {
			display: inline-block;
		}
	}

	@include phoneLandscape {
		width: auto;
	}

	@media (min-width: $lg_desktop_small_min) and (max-height: 900px) {
		width: auto;

		> p, form > p {
			width: 60%;
			margin-left: auto;
		}

		form > .inputs {
			width: 35%;
			margin-left: auto;
		}
	}

	.ant-form {
		top: 100px;
		padding: 0;

		@include tablet {
			width: 70%;
			margin-left: auto;
		}
	}
	.ant-form-item {
		margin-bottom: 0px !important;
	}
	.ant-form-item-control {
		margin: 0px 0 !important;
	}

	h3 {
		color: $grey;
		text-align: right;
		font-size: 2.6em;
		margin: 1rem 0;
		line-height: 1;
		font-weight: 300;
		@include phones {
			font-size: 2.5em;
		}
		@include tablet {
			font-size: 3rem;
		}
	}
	h3 span {
		color: $red;
		font-family: futura-pt, sans-serif;
		font-weight: 300;
	}
	p {
		margin-bottom: 0em;
	}
	.input-label {
		font-size: 1rem;
	}

	.ant-radio-wrapper {
		margin-right: 0px;
		font-size: 1rem;
		span {
			color: $grey;
			font-size: 1rem;
			font-family: neuzeit-grotesk, sans-serif;
			vertical-align: middle;

			@include phones {
				font-size: 1.2rem;
			}
		}
	}
	.ant-radio-wrapper:hover .ant-radio,
	.ant-radio:hover .ant-radio-inner,
	.ant-radio-input:focus + .ant-radio-inner {
		border-color: $grey !important;
		box-shadow: none;
	}
	.broker {
		margin-top: 10px;
	}
	.broker .ant-radio-group {
		float: right;
		font-family: neuzeit-grotesk, sans-serif;
		color: $grey;
		font-weight: $body-font-weight;
		.ant-radio-inner {
			background: $white;
		}
	}
	.ant-radio-checked {
		.ant-radio-inner {
			border-color: $grey;
		}

		&::after {
			animation: none !important;
			border: none;
		}
	}
	.ant-radio-inner::after {
		background: $grey;
	}
	.broker-text {
		margin-right: 10px;
		font-family: neuzeit-grotesk, sans-serif;
	}
	input[type="text"] {
		width: 100%;
		padding: 0.5rem 0;
		border-radius: 0px;
		background: $white;
		margin: 0;
		outline: none !important;
		box-shadow: none;
		border: 0px solid #707070ad;
		border-bottom: 1px solid #707070ad;
		text-align: right;
		border-right: none !important;
		font-family: neuzeit-grotesk, sans-serif;
		line-height: 1;

		&, &::placeholder {
			color: inherit;
			font-size: 1rem;

			@include phones {
				font-size: 1.2rem;
			}
		}
	}

	.submit-btn {
		height: 3em;
		line-height: 1;
		// width: 5.5em;
		float: right;
		color: $darkGrey;
		background: $white;
		border: 1px solid $darkGrey !important;
		border-radius: 0px;
		font-size: 1rem;
		text-shadow: 0 -1px 0 rgba(0, 0, 0, 0) !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		&::after {
			animation: none !important;
		}
		@include phones {
			font-size: 1.2rem !important;
		}
	}
	.submit-btn:hover,
	.submit-btn:focus {
		background: transparent; 
		border-color: $grey !important;
		color: $grey;
	}

	@include responsive {
		// padding: 0 1rem 1rem;
		width: 100%;
	}

	@include tabletLandscape {
		width: 75%;
	}
}

.has-error .ant-input,
.has-error .broker-text {
	&:hover {
		background-color: transparent !important;
	}

	&:focus {
		box-shadow: none !important;
	}

	color: red !important;
}

.logos {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-content: center;
	margin-bottom: 0.1rem;
}

.logo {
	height: calc(50vw * 0.3 * 0.153);
	margin-left: 0.5rem;

	@include phones {
		height: calc(100vw * 0.5 * 0.153);
	}

	@include tablet {
		height: calc(100vw * 0.3 * 0.153);
	}
}

@include tablet {
	.terms {
		font-size: 0.7em !important;
	}

	.contact-form-container {
		padding: 1rem;
	}
}

@include phones {
	.ant-form {
		h3 {
			font-size: 2.5em !important;
		}
		padding: 1rem;
		width: 100%;
		p {
			font-size: 1.2rem;
		}
	}

	.terms {
		font-size: 0.7rem !important;
	}

	.form-row-container {
		height: auto;
		position: static;
		padding: 0;
	}

	.contact-container {
		// margin-top: 1rem;
		// height: calc((#{$vh} * 100) - 7em);
	}
}

@include desktop {
	.contact-form-container {
		.ant-form,
		.ant-radio-group,
		.ant-form-item {
			// font-size: 16px !important;
		}
		.terms {
			font-size: 0.7em !important;
		}
	}
}
